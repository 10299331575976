import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material'

export const Theme = (props: React.PropsWithChildren<unknown>) => {
  const customTheme = {
    palette: {
      primary: {
        main: '#3c91e6',
        light: '#1273eb',
        dark: '#0064b3',
        blueLight: '#64b5f6',
      },
      secondary: {
        main: '#ededed',
        light: '#ffffff',
        dark: '#bbbbbb',
      },
      additionalColors: {
        title: '#00234C',
        blueLight: '#DAEEFE',
        yellowLight: '#FFBD17C4',
        iceGray: '#EBEBEB',
        greyDisabled: '#A8B1CE8A',
        green: '#378453',
      },
      success: {
        main: '#0A9168',
        light: '#0A9168',
        dark: '#0A9168',
      },
      customShadows: {
        shadow1: '0 5px 20px 0 rgb(0 0 0 / 20%)',
        shadow2: '0 5px 10px 0 rgb(0 0 0 / 15%)',
      },
    },
    typography: {
      fontFamily: 'Montserrat, sans-serif',
    },
    components: {
      MuiChip: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: '#DAEEFE',
          },
          colorSecondary: {
            backgroundColor: 'brown',
          },
        },
      },
    },
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme(customTheme)}>
        {props.children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

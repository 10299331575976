import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQuery } from '@services/base-query'
import { formatDoctorWorkWindow } from '@utils/format-doctor-work-window/format-doctor-work-window'

import { ISession } from './session.model'

export const sessionApi = createApi({
  reducerPath: 'sessionApi',
  baseQuery: baseQuery(),
  endpoints(builder) {
    return {
      session: builder.mutation<
        ISession.Create.FormattedResponse,
        ISession.Create.Request
      >({
        query: body => {
          return {
            url: 'accounts/doctor/session',
            method: 'POST',
            body,
          }
        },
        transformResponse(response: ISession.Create.Response) {
          const { doctor } = response

          return {
            ...response,
            doctor: {
              ...doctor,
              workWindow: formatDoctorWorkWindow(doctor.serializedWorkWindows),
            },
          }
        },
      }),
      resendConfirmationEmail: builder.mutation<
        void,
        Pick<ISession.Create.Request, 'email'>
      >({
        query: body => {
          return {
            url: 'accounts/doctor/confirmation/resend',
            method: 'POST',
            body,
          }
        },
      }),
      sendForgotPasswordEmail: builder.mutation<
        void,
        Pick<ISession.Create.Request, 'email'>
      >({
        query: body => {
          return {
            url: 'accounts/doctor/forgot',
            method: 'POST',
            body,
          }
        },
      }),
    }
  },
})

export const {
  useSessionMutation,
  useResendConfirmationEmailMutation,
  useSendForgotPasswordEmailMutation,
} = sessionApi

import { IDoctor, IWorkWindowMapKey } from '@model/doctor/doctor'

export const formatDoctorWorkWindow = (
  serializedWorkWindows: IDoctor['serializedWorkWindows'],
) => {
  const weekday = String(
    new Date().getUTCDay(),
  ) as typeof IWorkWindowMapKey[number]

  const workday = serializedWorkWindows.find(item =>
    item.days.includes(weekday),
  )

  return workday ? `${workday.startAt}-${workday.endAt}` : null
}

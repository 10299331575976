import {
  Check,
  Clear,
  DoubleArrow,
  EventAvailable,
  PlayArrow,
} from '@mui/icons-material'
import { Theme } from '@mui/material'

import { Status } from '@model/appointment/appointment'

export type ITranslateStatusResponse =
  | 'Confirmada'
  | 'Cancelada'
  | 'Check-in realizado'
  | 'Finalizada'
  | 'Em progresso'
  | 'Agendada'

export interface ITranslateStatus {
  (status?: string): ITranslateStatusResponse
}

export const translateStatus: ITranslateStatus = status => {
  switch (status) {
    case Status.Confirmed:
      return 'Confirmada'
    case Status.Canceled:
      return 'Cancelada'
    case Status.Checked_In:
      return 'Check-in realizado'
    case Status.Finished:
      return 'Finalizada'
    case Status.In_Progress:
      return 'Em progresso'
    case Status.Scheduled:
      return 'Agendada'
    default:
      return 'Agendada'
  }
}

export const checkGreenStatus = (status: ITranslateStatusResponse) =>
  status === 'Confirmada' ||
  status === 'Check-in realizado' ||
  status === 'Em progresso' ||
  status === 'Finalizada'

export const statusColor = (status: keyof typeof Status) => {
  const colorMap = {
    [Status.Scheduled]: (theme: Theme) => theme.palette.secondary.dark,
    [Status.Confirmed]: (theme: Theme) => theme.palette.success.main,
    [Status.Checked_In]: (theme: Theme) => theme.palette.primary.main,
    [Status.In_Progress]: (theme: Theme) => theme.palette.primary.light,
    [Status.Finished]: (theme: Theme) => theme.palette.primary.light,
    [Status.Canceled]: (theme: Theme) => theme.palette.error.main,
  }

  return colorMap[status]
}

export const statusAppointmentColor = (
  theme: Theme,
  status: keyof typeof Status,
) => {
  const textColor = '#000'
  const opacity = 1

  const colorMap = {
    [Status.Scheduled]: {
      textColor,
      bgColor: theme.palette.additionalColors.yellowLight,
      opacity,
    },
    [Status.Confirmed]: {
      textColor,
      bgColor: theme.palette.primary.light,
      opacity,
    },
    [Status.Checked_In]: {
      textColor,
      bgColor: theme.palette.primary.light,
      opacity,
    },
    [Status.In_Progress]: {
      textColor,
      bgColor: theme.palette.additionalColors.blueLight,
      opacity,
    },
    [Status.Finished]: {
      textColor,
      bgColor: theme.palette.additionalColors.green,
      opacity,
    },
    [Status.Canceled]: {
      textColor,
      bgColor: theme.palette.additionalColors.greyDisabled,
      opacity: 0.7,
    },
  }

  return (
    colorMap[status] || {
      textColor,
      bgColor: theme.palette.secondary.dark,
    }
  )
}

export function statusIcon(status: keyof typeof Status) {
  const iconMap = {
    [Status.Scheduled]: EventAvailable,
    [Status.Confirmed]: PlayArrow,
    [Status.Checked_In]: DoubleArrow,
    [Status.In_Progress]: DoubleArrow,
    [Status.Finished]: Check,
    [Status.Canceled]: Clear,
  }

  return iconMap[status]
}

/* eslint-disable import/no-duplicates */
import {
  format,
  isAfter,
  parse,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
  subDays,
  subHours,
  addDays,
  isThisMonth,
  differenceInYears,
  subMinutes,
  getHours,
  getMinutes,
} from 'date-fns'
import { pt } from 'date-fns/locale'

export const normalizedDate = (
  date = new Date(),
  isoString = true,
  hours = 0,
  minutes = 0,
  seconds = 0,
  milliseconds = 0,
) => {
  const normalizedDate = setMilliseconds(
    setSeconds(setMinutes(setHours(date, hours), minutes), seconds),
    milliseconds,
  )

  if (isoString) {
    return normalizedDate.toISOString()
  }

  return normalizedDate
}

export const dateMask = (raw?: string) => {
  if (!raw) return ''

  if (/[^0-9/]/.test(raw)) return ''

  const cleaned = raw.replace(/\D/g, '')

  return cleaned.replace(/(\d{2})(\d{2})?(\d{4})?/, (_, day, month, year) => {
    if (day > 31) return ''
    if (month > 12) return ''
    if (year > new Date().getFullYear()) return ''
    return [day, month, year].join('/')
  })
}

export const formatDate = (
  date: Date | string = new Date(),
  year = false,
  formatDate = 'dd/MM/yyyy',
) => {
  const parsedDate = typeof date === 'string' ? new Date(date) : date

  return format(parsedDate, year ? formatDate : 'dd/MM', { locale: pt })
}

export const addDaysToDate = (date = new Date(), days = 1) =>
  addDays(date, days)

export const subDaysToDate = (date = new Date(), days = 1) =>
  subDays(date, days)

export const subHoursToDate = (date = new Date(), hours = 1) =>
  subHours(date, hours)

export const formatTime = (date: string) =>
  format(new Date(date), 'HH:mm', { locale: pt })

export const parseDate = (date: string) => parse(date, 'dd/MM/yyyy', new Date())

export const timeToDate = (time: string, date = new Date()) => {
  const [hours, minutes] = time.split(':')

  return normalizedDate(date, true, Number(hours), Number(minutes)) as string
}

export const isAfterToDate = (date1: Date, date2 = new Date()) =>
  isAfter(date1, date2)

export const getAge = (birthday: string) => {
  const parsedBirthday = parseDate(birthday)

  return differenceInYears(new Date(), parsedBirthday)
}

export const isInMonth = (date: Date | string) => {
  const parsedDate = typeof date === 'string' ? new Date(date) : date

  return isThisMonth(parsedDate)
}

export const getUTCDate = (date: Date = new Date()): Date => {
  return subMinutes(date, date.getTimezoneOffset())
}

export const timeToMinutes = (time: string): number => {
  const parsedTime = parse(time, 'HH:mm', new Date())
  return getHours(parsedTime) * 60 + getMinutes(parsedTime)
}

import { Theme } from '@mui/material'

import { IAppointment } from '@model/appointment/appointment'
import { IAppointments } from '@slices/appointments/appointments.model'
import { statusAppointmentColor, translateStatus } from '@utils/status/status'

export const formatAppointmentsToCalendar = (
  theme: Theme,
  appointments: (IAppointment & { count?: number; status?: string })[],
) => {
  if (appointments.length === 0) return []

  return appointments.map(({ patient, statuses, status, count, ...rest }) => ({
    ...rest,
    title: patient.name,
    translatedStatus: {
      name: translateStatus(statuses[0].status),
      ...statusAppointmentColor(theme, statuses[0].status),
    },
    status: statuses[0].status ?? '',
  }))
}

export const formatEvents = (
  appointmentsList?: IAppointments.Get.FormattedResponse['appointments'],
): IAppointments.Get.FormattedResponse['appointments'] => {
  if (!appointmentsList) return []

  return appointmentsList?.map(item => ({
    ...item,
    start: new Date(item.start),
    end: new Date(item.end),
  }))
}
